import { useQuery } from '@graphcommerce/graphql'
import { ProductCardSkeleton } from '../Skeleton/ProductCardSkeleton'
import { AmMostviewedGroupsDocument } from './AmMostviewedGroups.gql'
import { AmMostviewedGroupsByPositionDocument } from './AmMostviewedGroupsByPosition.gql'
import { RelatedProductGroup } from './RelatedProductGroup'

type RelatedProductGroupsProps = {
  uid?: string
  position: string
  classes?: {
    list?: string
  }
  displayOnMinicart?: boolean
  block_title?: string
}

export function RelatedProductGroups(props: RelatedProductGroupsProps) {
  const { position, uid, classes, block_title, displayOnMinicart } = props
  const document = uid ? AmMostviewedGroupsDocument : AmMostviewedGroupsByPositionDocument
  const { data, loading } = useQuery(document, {
    variables: uid
      ? {
          uid,
          position,
        }
      : {
          position,
        },
    fetchPolicy: 'cache-and-network',
  })

  const groups = data?.amMostviewedGroups?.items

  return (
    <>
      {loading && (
        <div className='mt-25 flex w-full items-center justify-center'>
          <div
            className='mx-[20px] w-full max-w-maxContentWidth
                md:mb-5'
          >
            <div className='relative flex items-center py-5 lg:mx-0'>
              <span
                className='Type-XXL-Regular
                  lg:Type-H4-Regular md:Type-XXXL-Regular text-black sm:italic'
              >
                {block_title}
              </span>
            </div>
            <div className={`grid grid-cols-2 gap-[20px] md:gap-[20px] ${classes?.list || ''}`}>
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <div className='hidden md:flex'>
                <ProductCardSkeleton />
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading &&
        groups &&
        groups?.length > 0 &&
        groups?.map((group) => (
          <RelatedProductGroup
            key={block_title ?? group?.block_title?.replaceAll(' ', '_')}
            classes={classes}
            {...group}
            block_title={block_title ?? group?.block_title}
            displayOnMinicart={displayOnMinicart}
            loading={loading}
          />
        ))}
    </>
  )
}
